import React from 'react';
import '../../assets/mac.png'; 
import '../../css/mac.css';
import folder from '../../assets/folder.png'
import { useNavigate } from 'react-router-dom';

const Mac = (props) => {

const navigate = useNavigate();

const handleClick = () => {
  navigate('/home');
}


  return (
   <>
 <div className="gif">
 <div className="bg">
  <div className="mac">
  <div className="tube-shape">
    <div className="stick-content" >
      <div className="home-content">

  
      <div class="flex-grid-center">
			<div class="mac-window">
				<div class="title-bar">
					<div class="bars-container">
						<div class="bar"></div>
						<div class="bar"></div>
						<div class="bar"></div>
						<div class="bar"></div>
						<div class="bar"></div>
						<div class="bar"></div>
					</div>
					<div class="close"><div class="inner"></div></div>
					<div class="title">StickGonBang</div>
				</div>
			
        <div className="mac-window-content"onClick={handleClick}> 	<img className="folder"  src={folder} alt="stickgonbang" />
        <p>BANG</p></div>
			</div>


    </div>
      </div>
    </div>
  </div>
   </div>
  </div>
 </div>
   </>
  )
}

export default Mac;