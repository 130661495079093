import React from "react";
import logo from "../../assets/logo.png";
import "./styles1.css";
import "./styles2.css";
import "./styles3.css";
import bgImg from "../../assets/cover.png";
// import stick from "../../assets/stick2.png";
import t1 from "../../assets/t1.png";
import t2 from "../../assets/t2.png";
import t3 from "../../assets/t3.png";
import t4 from "../../assets/t4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faInstagram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

const Home = (props) => {
  const goHere = (e, l) => {
    e.preventDefault();
    window.open(l, "_blank");
  }
  return (
    <>
      {/*[if lt IE 7 ]><html class="ie ie6" lang="en"> <![endif]*/}
      {/*[if IE 7 ]><html class="ie ie7" lang="en"> <![endif]*/}
      {/*[if IE 8 ]><html class="ie ie8" lang="en"> <![endif]*/}
      {/*[if (gte IE 9)|!(IE)]><!*/} {/*<![endif]*/}
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="description" content="Neoh" />
      <meta name="author" content="Frenify" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <title>StickGonBang</title>
      {/* Google Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      {/* !Google Fonts */}
      {/* Styles */}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css?ver=5.0" />
      {/*[if lt IE 9]>  <![endif]*/}
      {/* !Styles */}
      {/* Main */}
      <div className="neoh_fn_main" data-footer-sticky="">
        {/* !Right Navigation */}
        {/* Header */}
        <nav
          class="navbar navbar-expand-lg navbar-dark sticky-top bg-transparent"
          style={{
            zIndex: 999,
            padding: 0,
            display: "float",
            marginTop: "-90px",
          }}
        >
          <a class="navbar-brand" href="#">
            {" "}
            <div className="logo" style={{ padding: 16 }}>
              <a href="index.html">
                <img
                  className="hover"
                  src={logo}
                  alt=""
                  style={{ height: "3rem" }}
                />
              </a>
            </div>
          </a>
          <div style={{ padding: 16 }}>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul
              class="navbar-nav"
              style={{ marginTop: 8, margin: "0px", paddingLeft: 16 }}
            >
                <li class="nav-item">
                <a class="nav-link nav-link-text" href="#contact">
                  Contact
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-text" href="#music">
                  Music
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-text" href="#shop">
                  Shop
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {/* !Header */}
        {/* Content */}
        <div className="neoh_fn_content" style={{ overflowX: "clip" }}>
          {/* Hero Header */}
          <div className="neoh_fn_hero">
            {/* Overlay (of hero header) */}
            <div className="bg_overlay ">
              {/* Overlay Color */}
              <div className="bg_color" />
              {/* !Overlay Color */}
              {/* Overlay Slider */}
              <div className="overlay_slider vegas-slide ">
                <img
                  className="hover"
                  type="jpeg"
                  src={bgImg}
                  alt="stick"
                  style={{ width: "100%", height: "100%" }}
                />
                {/* <input type="hidden" defaultValue="img/drops/2.jpg" />
                        <input type="hidden" defaultValue="img/drops/3.jpg" />
                        <input type="hidden" defaultValue="img/drops/4.jpg" />
                        <input type="hidden" defaultValue="img/drops/5.jpg" /> */}
              </div>
              {/* !Overlay Slider */}
            </div>
            {/* Overlay (of hero header) */}
            <div className="hero_content">
              <div className="container column">
                <div className="content">
                  <div id="magic" />

                  <img
                    src={logo}
                    alt=""
                    className="fadeIn hover logo-hero-img "
                    style={{ width: "300px" }}
                  />

                  <p className="fn_desc fn_animated_text">
                    The collection built by and runby ‘frenify’. Together we are
                    strong than ever.
                  </p>
                </div>
              </div>
            </div>
            <a
              href="#contact"
              className="neoh_fn_down magic-hover magic-hover__square"
            >
              <span className="text">Scroll Down</span>
              <span className="icon">
                <img src="svg/right-arr.svg" alt="" className="fn__svg" />
              </span>
            </a>
          </div>
          {/* !Hero Header */}
          {/* About Section */}
          <section id="contact">
            <div className="container">
            <div className="neoh_fn_title">
                <div className="col px-3 text-center mb-5" >
                  <h1 style={{ fontWeight: 900 }}>Socials & Contact</h1>
                 
                </div>
              </div>
              <br />
             <div className="contact-container mt-5 mt-lg-3" >
      
                <a
                  href="https://www.instagram.com/stickgonbang"
                  style={{width: "300px", gap: "1rem"}}
                  target="_blank"
                  className="btn btn-danger"
                  rel="noreferrer"
                >
                  <span className="">
                 <FontAwesomeIcon icon={faInstagram} size="2xl" />
                  </span>
                  <span className="text">INSTAGRAM</span>
                </a>
                <a
                  href="https://twitter.com/stickgonbang"
                  style={{width: "300px", gap: "1rem"}}
                  target="_blank"
                  className="btn btn-danger"
                  rel="noreferrer"
                >
                  <span className="">
                 <FontAwesomeIcon icon={faXTwitter} size="2xl" />
                  </span>
                  <span className="text">X / TWITTER </span>
                </a>
                <a
                  href="https://www.youtube.com/@stickgonbang"
                  style={{width: "300px", gap: "1rem"}}
                  target="_blank"
                  className="btn btn-danger"
                  rel="noreferrer"
                >
                  <span className="">
                 <FontAwesomeIcon icon={faYoutube} size="2xl" />
                  </span>
                  <span className="text">YOUTUBE</span>
                </a>
                <a
                  href="https://discord.gg/KTTxWVQK"
                  style={{width: "300px", gap: "1rem"}}
                  target="_blank"
                  className="btn btn-danger"
                  rel="noreferrer"
                >
                  <span className="">
                 <FontAwesomeIcon icon={faDiscord} size="2xl" />
                  </span>
                  <span className="text">DISCORD</span>
                </a>
             </div>
            </div>
          </section>
          <section id="music" style={{marginTop: "120px"}}>
            <div className="container">
              {/* About Item #1 */}
              <div className="row px-3">
                <div className="col px-3 text-center">
                  <h1 style={{ fontWeight: 900 }}>Music by StickGonBang</h1>
                </div>
              </div>
              <div className="row px-3 pb-3">
                <div className="col-4">
                  <div onClick={(e)=> goHere(e,"https://music.apple.com/us/artist/stickgonbang/1529682619")} className="platform-link-box"> <img src="https://upload.wikimedia.org/wikipedia/commons/5/5f/Apple_Music_icon.svg" className="apple-icon"/><small id="apple-s"></small></div>
                </div>
                <div className="col-4">
                  <div onClick={(e)=> goHere(e,"https://open.spotify.com/artist/7fObxUTke2VNBvywOi0Fln")} className="platform-link-box"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Antu_spotify.svg/512px-Antu_spotify.svg.png" className="apple-icon"/> <small id="spotify-s"></small></div>
                </div>
                <div className="col-4">
                  <div onClick={(e)=> goHere(e,"https://soundcloud.com/soundbykeel")} className="platform-link-box"><img src="https://upload.wikimedia.org/wikipedia/commons/a/a2/Antu_soundcloud.svg" className="apple-icon"/> <small id="soundcloud-s"></small></div>
                </div>
              </div>
              <div className="row px-3 pt-2">
                <div className="col-lg-4" style={{ zIndex: 99 }}>
                  <iframe
                    src={`https://open.spotify.com/embed/artist/7fObxUTke2VNBvywOi0Fln?utm_source=generator`}
                    style={{
                      width: "100%",
                      height: "424px",
                      border: "none",
                      borderRadius: "12px",
                      zIndex: 9,
                    }}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                    title="Spotify Artist"
                  />
                </div>
                <div className="music-video col-lg-8 px-3">
                  <div className="">
                    <iframe
                    
                      src={`https://embed.music.apple.com/us/music-video/foreign-feat-malik-xavier-ricky-kanes/1656007153`}
                      style={{
                        width: "100%",
                        height: "424px",
                        border: "none",
                        borderRadius: "12px",
                        zIndex: 9,
                      }}
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                      title="Spotify Artist"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="neoh_fn_about_item row px-3">
              <iframe
                      src={`https://soundcloud.com/soundbykeel/sets/fuckboy-fall?si=8ae854d7c285408fa763c106fa1d8bc6`}
                      style={{
                        width: "100%",
                        height: "424px",
                        border: "none",
                        borderRadius: "12px",
                        zIndex: 9
                      }}
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                      title="Spotify Artist"
                    />
              </div> */}
              {/* !About Item #1 */}
            </div>
          </section>
          {/* !About Section */}

          {/* Drops Section */}
          <section id="shop">
            <div className="container">
              {/* Main Title */}
              <div className="neoh_fn_title">
                <div className="col px-3 text-center" style={{marginBottom: 80, marginTop: 240}}>
                  <h1 style={{ fontWeight: 900 }}>Exclusive Merch</h1>
                </div>
              </div>
              {/* !Main Title */}
              {/* Drops List */}
              <div className="neoh_fn_drops">
                <ul>
                  <li>
                    <div className="item" data-included="on">
                      <div className="img_holder point">
                        <img src={t1} alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title hover">
                          <a href="">Sold Out</a>
                        </h3>
                      </div>
                      {/* Modal Information */}

                      {/* !Modal Information */}
                    </div>
                  </li>
                  <li>
                    <div className="item" data-included="on">
                      <div className="img_holder">
                        <img src={t2} alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title hover">
                          <a href="">Sold Out!</a>
                        </h3>
                      </div>
                      {/* Modal Information */}

                      {/* !Modal Information */}
                    </div>
                  </li>
                  <li>
                    <div className="item" data-included="on">
                      <div className="img_holder">
                        <img src={t3} alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title hover">
                          <a href="">Sold Out!</a>
                        </h3>
                      </div>

                      {/* !Modal Information */}
                    </div>
                  </li>
                  <li>
                    <div className="item" data-included="on">
                      <div className="img_holder ">
                        <img src={t4} alt="" />
                      </div>
                      <div className="title_holder">
                        <h3 className="fn_title hover">
                          <a href="#">Sold Out!</a>
                        </h3>
                      </div>
                      {/* Modal Information */}

                      {/* !Modal Information */}
                    </div>
                  </li>
                </ul>
                {/* Clearfix */}

                {/* Main Button */}

                {/* !Main Button */}
              </div>
              {/* !Drops List */}
            </div>
          </section>
          {/* !Drops Section */}
        </div>
        {/* Content */}
        {/* Footer */}
        <footer id="footer">
          <div className="neoh_fn_footer">
            {/* Footer Top */}
            <div className="footer_top">
              <div className="container">
                {/* <div className="ft_in">
                  <div className="desc">
                    <div className="img">
                      <img src="img/footer-logo.png" alt="" />
                    </div>
                    <h3 className="fn_title">Join Now For Early Access</h3>
                    <p className="fn_desc">
                      Morbi non dignissim erat, a blandit felis nec lorem vel
                      orci varius congue ut vitae est. Nam quis tempus nisl.
                      Fusce posuere nibh a mi molestie, sit amet ornare
                    </p>
                  </div>
                  <div className="subscribe_form">
                    <div className="subscribe_in">
                      <input type="text" placeholder="Email..." />
                      <a href="#" className="neoh_fn_button only_text">
                        <span className="text">Subscribe</span>
                      </a>
                    </div>
                    <div
                      className="returnmessage"
                      data-success="Your message has been received, We will contact you soon."
                      data-message="You have subscribed to our updates. Thank you"
                      data-invalid-email="Please enter valid email!"
                    />
                    <div className="empty_notice">
                      <span>Please enter your Email</span>
                    </div>
                  </div>
                  <div className="neoh_fn_social_list">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fn-icon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fn-icon-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fn-icon-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fn-icon-pinterest" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fn-icon-behance" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            {/* !Footer Top */}
            {/* Footer Bottom */}
            <div className="footer_bottom">
              <div className="container">
                <div className="fb_in">
                  <div className="fb_left">
                    <p>Copyright 2024 - StickGonBang Media</p>
                  </div>
                  <div className="fb_right">
                    <ul>
                      <li>
                        <a href="#music">Music</a>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li>
                      <li>
                        <a href="#shop">Shop</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* !Footer Bottom */}
          </div>
        </footer>
        {/* !Footer */}
        {/* Totop */}
        <a href="#" className="neoh_fn_totop">
          <span className="arrow">
            <img src="svg/right-arr.svg" alt="" className="fn__svg" />
          </span>
          <span className="circle">
            <img src="svg/circle.svg" alt="" className="fn__svg" />
          </span>
        </a>
        {/* !Totop */}
      </div>
      {/* !Main */}
      {/* Scripts */}
      {/*[if lt IE 10]>  <![endif]*/}
      {/* !Scripts */}
    </>
  );
};

export default Home;
