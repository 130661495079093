import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/plugins.css";
import "./css/style.css";
import "./css/vegas.min.css.map";
import "./css/font/fontello.eot";
import "./css/font/fontello.svg";
import "./css/font/fontello.ttf";
import "./css/font/fontello.woff";
import "./css/font/fontello.woff2";
import Home from "./containers/Landing/Home";
import Mac from "./containers/Landing/Mac";
import "./App.css"

const App = () => {
  return (
    <BrowserRouter>
      {/* Routes */}
      <Routes>
         {/* Landing */}
         <Route path="/home" element={<Home />} />
         <Route path="/" element={<Mac/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
